$bootstrap-sass-asset-helper: false;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$icon-font-name: "glyphicons-halflings-regular";
$cr-path-to-icons-sprite: "/crds-styles/assets/svgs/";
@import "~crds-styles/assets/stylesheets/crds-styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
h2 {
  @extend .flush-top;
  @extend .hard-top;
  @extend .font-family-condensed-extra;
  color: $cr-blue-dark !important;
  @extend .push-quarter-bottom;
  padding-top: 20px;
  text-transform: uppercase;
}

.section-header {
  margin-top: 0px;
}

.page {
  padding-top: 48px;
}

.toast-container.toast-crds .ngx-toastr {
  width: 100%;
  font-family: "Times New Roman", Times, serif;
  font-style: italic;
}

.toast-success {
  background-color: #149c64;
}